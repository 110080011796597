<template>
  <div>
    <basic-container>
      <el-dialog
          title="添加机台"
          v-if="addVisible"
          :visible.sync="addVisible"
          width="30%"
          :append-to-body="true">
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
          <el-form-item label="开始锭数" prop="start">
            <el-input v-model="ruleForm.start"></el-input>
          </el-form-item>
          <el-form-item label="结束锭数" prop="end">
            <el-input v-model="ruleForm.end"></el-input>
          </el-form-item>
        </el-form>
        <p slot="footer" class="dialog-footer" style="margin-top: -30px;">
          <el-button @click="addVisible = false;ruleForm.start = ''; ruleForm.end = ''">取 消</el-button>
          <el-button type="primary" @click="addConfirm">确 定</el-button>
        </p>
      </el-dialog>
      <el-dialog
          title="人员机台设定"
          v-if="dialogVisible"
          :visible.sync="dialogVisible"
          width="60%"
          :append-to-body="true">
        <div class="machineClass">
          <el-collapse v-model="activeNames">
            <el-collapse-item title="人员信息" name="1">
              <el-form align="center" class="content" ref="ruleform" label-width="70px">
                <el-row :gutter="24">
                  <el-col :span="8">
                    <el-form-item label="人员:">
                      <el-input v-model="person" :disabled="true"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item label="班组:">
                      <el-input v-model="classAssign" :disabled="true"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item label="车间:">
                      <el-input v-model="wkshp" :disabled="true"></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row :gutter="24">

                  <el-col :span="8">
                    <el-form-item label="岗位:">
                      <el-input v-model="postName" :disabled="true"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item label="工序:">
                      <el-input v-model="processName" :disabled="true"></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-form>
            </el-collapse-item>
            <el-collapse-item title="机台设定" name="2">
              <avue-crud
                  :option="classOption"
                  :data="classData"
                  @row-del="handleDel"
                  style="display: block;overflow: hidden;height: 350px;overflow: scroll !important;">
                <template slot="menuLeft">
                  <div>
                    <!-- <el-input v-model="nameorcode" size="small" placeholder="请查找机台" style="width:150px;"></el-input> -->
                    <el-autocomplete
                        popper-class="my-autocomplete"
                        v-model="nameorcode"
                        :fetch-suggestions="querySearch"
                        placeholder="请输入内容"
                        @select="handleSelect">
                      <template slot-scope="{ item }">
                        <div class="name">{{ item.code }}</div>
                        <!-- <span class="addr">{{ item.address }}</span> -->
                      </template>
                    </el-autocomplete>
                    <!-- <el-button @click="confirmDriver" size="small" style="marginLeft:5px;" type="primary">确认机台</el-button> -->
                    <el-button @click="addDriver" size="small" style="marginLeft:5px;" type="primary">添加</el-button>
                  </div>

                </template>
              </avue-crud>
            </el-collapse-item>
          </el-collapse>

        </div>
        <p slot="footer" class="dialog-footer" style="margin-top: -30px;">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="confirm" :disabled="classData.length == 0 ? true : false">确 定</el-button>
        </p>
      </el-dialog>
      <avue-crud :option="tableOption"
                 :data="tableData"
                 :table-loading="tableLoading"
                 :page="page"
                 ref="crud"
                 @refresh-change="refreshChange"
                 @row-save="handleSave"
                 @current-change="currentChange"
                 @size-change="sizeChange"
                 @row-update="handleUpdate"
                 @row-del="handleDel">
        <template slot="machineCode" slot-scope="{row}">
          <el-link style="color: #409EFF;text-style: none;" @click="clickLink(row)">
            {{ row.machineCode || '无' }}
          </el-link>
        </template>
        <template slot="menuRight">
          <el-select filterable v-model="form.workShopId" placeholder="车间" size="small"
                     style="width:100px !important;marginRight: 5px;" @change="cjChange">
            <el-option v-for="(item,index) in calssLists" :key="index" :label="item.name" :value="item.id"></el-option>
          </el-select>
          <el-select filterable v-model="form.groupId" placeholder="班组" size="small"
                     style="width:100px !important;marginRight: 5px;">
            <el-option v-for="(item,index) in refrechLists" :key="index" :label="item.name"
                       :value="item.id"></el-option>
          </el-select>
          <el-select filterable clearable v-model="form.processId" placeholder="工序" size="small" @change="gxChange"
                     style="width:100px !important;marginRight: 5px;">
            <el-option v-for="item in wklnLists" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
          <el-select filterable clearable v-model="form.postId" placeholder="岗位" size="small"
                     style="width:100px !important;marginRight: 5px;">
            <el-option v-for="item in workLists" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
          <el-select filterable clearable v-model="form.machineId" placeholder="设备" size="small"
                     style="width:100px !important;marginRight: 5px;">
            <el-option v-for="item in mechineList" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
          <el-input clearable v-model="form.userName" placeholder="员工姓名"
                    style="display:inlie-block;width:200px;marginRight: 5px;"
                    size="small"></el-input>
          <!--<el-button type="primary" size="small" icon="el-icon-search" @click="search">{{$t("search")
          }}</el-button>-->
        </template>
      </avue-crud>
    </basic-container>
  </div>
</template>
<script>
import {list, getMachineList, MachineList, addMachine} from "@/api/machineSetting";
import {
  machinelist,
  workList,
  wklnList,
  saveUserMa,
  getBZ,
  refrechList,
  calssList,
  getCJAndGZ,
  getMachineListAll,
  machineUsersdelete
} from "@/api/config";

export default {
  data() {
    return {
      searchList: [],//设备数组
      groupId: '', //班组ID
      machineId: '',
      activeNames: ['1', '2'],
      ruleForm: {
        start: '',
        end: '',
      },
      rules: {
        start: [
          {required: true, message: '请输入开始锭数', trigger: 'blur'},
        ],
        end: [
          {required: true, message: '请输入结束锭数', trigger: 'change'}
        ]
      },
      nameMachine: '',
      valueTransfer: [],
      tableLoading: false,
      classData: [],
      dataTransfer: [],
      //添加机台
      addVisible: false,
      workValue: "",
      wklnValue: "",
      postName: '',
      processName: '',
      refrechValue: "",
      calssValue: "",
      cjId: '',
      gxId: '',
      //班组
      refrechLists: [],
      //工序
      wklnLists: [],
      // 设备
      mechineList: [],
      //车间
      calssLists: [],
      //岗位
      workLists: [],
      //当前编辑的人员
      userId: "",
      //机台
      nameorcode: "",
      classAssign: "甲班",
      wkshp: "主车间",
      person: "人物",
      dialogVisible: false,
      page: {
        pageSize: 10,
        // pagerCount: 50,
        total: 10,
        pageNo: 1,
      },
      form: {
        workShopId: '',
        groupId: '',
        processId: '',
        postId: '',
        machineId: '',
        userName: ''
      },
      total: 0,
      input: "",
      tableData: [{disabledMenu: true}],
      classOption: {
        refreshBtn: false,
        addBtn: false,
        editBtn: false,
        delBtn: true,
        menuWidth: 200,
        selection: false,
        searchSpan: 5,
        // menu: false,
        columnBtn: false,
        column: [{
          label: "设备编码",
          prop: "machineCode"
        }, {
          label: "设备名称",
          prop: "machineName"
        },
          // {
          //   label: "所属工序",
          //   sortable: true,
          //   prop: "workShopName"
          // },
          {
            label: "开始锭号",
            sortable: true,
            slot: true,
            prop: "spinStart"
          }, {
            label: "结束锭号",
            sortable: true,
            slot: true,
            prop: "spinEnd"
          }, {
            label: "负责锭数",
            sortable: true,
            prop: "spin"
          }]
      },
      tableOption: {
        refreshBtn: true,
        addBtn: false,
        delBtn: true,
        menuWidth: 200,
        searchSpan: 5,
        menu: false,
        columnBtn: false,
        column: [{
          label: "姓名",
          prop: "name"
        }, {
          label: "责任机台",
          slot: true,
          prop: "machineCode"
        }, {
          label: "车间",
          prop: "workShopName"
        }, {
          label: "班组",
          prop: "groupName"
        }, {
          label: "岗位",
          prop: "postName"
        }, {
          label: "所属工序",
          prop: "processName"
        }]
      }
    }
  },
  watch: {
    dialogVisible: function (newval, oldval) {
      this.refresh();
    }
  },
  created() {
    wklnList().then(res => {
      this.wklnLists = res.data;
    });
    workList().then(res => {
      this.workLists = res.data.data;
    });
    this.getCJ()
    this.getMachineList()
  },
  methods: {
    search() {
      this.list();
    },
    querySearch(queryString, cb) {
      MachineList(queryString).then(res => {
        console.log(res);
        this.searchList = res.data.data.items
      })
      var restaurants = this.searchList;
      var results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants;
      // 调用 callback 返回建议列表的数据
      cb(results);
    },
    createFilter(queryString) {
      return (restaurant) => {
        if (restaurant.code !== null) {
          return (restaurant.code.toLowerCase().indexOf(queryString.toLowerCase()) !== -1);
        }

      };
    },
    handleSelect(item) {
      console.log(item)
      this.ruleForm.start = 1;
      this.ruleForm.end = item.spinCount;
      this.nameorcode = item.code;
      this.nameMachine = item.name;
      this.machineId = item.id;
    },
    cjChange(val) {
      console.log('车间', val);
      this.cjId = val
      getMachineList(val, this.gxId).then(res => {
        console.log('设备', res);
        this.mechineList = res.data.data.items
      })
      this.form.groupId = ''
      this.getbanzu(val)
    },
    getMachineList() {
      getMachineList(this.cjId, this.gxId).then(res => {
        console.log('设备', res);
        this.mechineList = res.data.data.items
      })
    },
    gxChange(val) {
      this.gxId = val
      console.log('工序', val);
      getMachineList(this.cjId, val).then(res => {
        console.log('设备', res);
        this.mechineList = res.data.data.items
      })
    },
    // 获取车间
    async getCJ() {

      const res = await getCJAndGZ(1)
      this.form.workShopId = res.data.data[0].id
      this.calssLists = res.data.data
      await this.getbanzu(res.data.data[0].id)
      await this.list();
      console.log(res, 'res')
    },
    // 获取班组
    async getbanzu(val) {
      const res = await getBZ(val)
      this.refrechLists = res.data.data
      this.form.groupId = res.data.data[0].id
      console.log(res, 'res')
    },
    handleSave() {

    },
    transferChange(val) {
      console.log(this.dataTransfer, this.classData);
      console.log(val, 'val')
    },
    // 确认机台
    addConfirm() {
      console.log(this.classData);
      let obj = {
        machineCode: this.nameorcode,
        machineName: this.nameMachine,
        spinStart: this.ruleForm.start,
        spinEnd: this.ruleForm.end,
        spin: this.ruleForm.end - this.ruleForm.start + 1,
        userId: this.userId,
        groupId: this.groupId,
        machineId: this.machineId
      };
      this.classData.push(obj)
      // let arr = []
      // this.dataTransfer.forEach(item=>{
      //   this.valueTransfer.forEach(val=>{
      //     if(item.id === val){
      //       arr.push(item)
      //     }
      //   })
      // })
      // arr.forEach(item=>{
      //   item.spin = item.spinCount
      //   item.spinStart = item.spinStart || 1
      //   item.spinEnd = item.spinEnd || item.spinCount
      //   item.workShopName = '粗砂'
      //   item.machineId = item.id
      //   item.userId = this.userId
      // })
      // console.log(arr,'arr');
      // console.log(this.valueTransfer,'valueTransfer')
      // this.classData = []
      // this.classData = JSON.parse(JSON.stringify(arr))
      this.$forceUpdate()
      // this.list();
      this.ruleForm.start = '';
      this.ruleForm.end = '';
      this.addVisible = false;
      this.ruleForm.start = ''; this.ruleForm.end = ''
      this.$message({
        message: '添加成功',
        type: "success"
      })
    },
    async confirm() {
      await saveUserMa(this.classData)
      this.dialogVisible = false
      this.list()
    },
    // 添加机台
    addDriver() {
      addMachine(this.nameorcode).then(res => {
        if (res.data.code == '0000') {
          this.addVisible = true;
        }
        console.log(res);
      })
      // console.log(this.classData)
      // getMachineListAll(this.nameorcode).then(res => {
      //   console.log(res);
      //   this.dataTransfer = res.data.data;
      // });
      // // //将分配机台添加到闲置机台
      // this.valueTransfer = [];
      // this.classData.forEach(v => {
      //   //添加机器id
      //   this.valueTransfer.push(v.machineId);
      // })
      // console.log(this.dataTransfer, this.valueTransfer);
      // //获取全部机器

    },
    //计算锭数
    handleChange(row) {
      row.spin = row.spinEnd - row.spinStart + 1;
    },
    refresh() {
      this.nameorcode = "";
    },
    // confirmDriver() {
    //   this.$set(this, "classData", []);
    //   list(this.nameorcode, this.userId).then(res => {
    //     console.log(res);
    //     // res.data.data[0].machines.forEach(v => {
    //     //   //工序
    //     //   v.workShopName = this.processName;
    //     // });
    //     this.$set(this, "classData", res.data.data[0].machines);
    //     console.log(this.classData);
    //     this.$forceUpdate();
    //   });
    // },
    clickLink(row) {
      console.log('row', row);
      this.userId = row.userId;
      this.groupId = row.departmentId;
      this.person = row.name;
      this.wkshp = row.workShopName;
      this.classAssign = row.groupName;
      this.postName = row.postName;
      this.processName = row.processName;
      this.processName = row.processName;
      this.classData = this.tableData.find(v => {
        return v.userId == row.userId;
      }).machines;
      this.classData.forEach(v => {
        //强行计算
        this.handleChange(v);
        v.workShopName = row.processName;
      })
      this.dialogVisible = true;
    },
    currentChange(pageNo) {
      this.page.pageNo = pageNo;
      this.list();
    },
    handleUpdate(row, index, done) {
      edit(row).then(() => {
        this.list();
        done();
      });
    },
    sizeChange(pageSize) {
      this.page.pageNo = 1;
      this.page.pageSize = pageSize;
      this.list();
    },
    handleDel(row) {
      this.$confirm("确定要删除吗", "提示", {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.classData.splice(row.$index, 1)
        machineUsersdelete(row.id).then((response) => {
          if (response.data.code == '0000') {
            this.dialogVisible = false;
            this.page.total = 1;
            this.list();
            this.$message.success('删除成功')
          }
        })
      })
    },
    refreshChange() {
      this.list();
    },
    list() {
      let obj = {
        pageSize: this.page.pageSize,
        total: this.page.total,
        pageNo: this.page.pageNo,
        workShopId: this.form.workShopId,
        groupId: this.form.groupId,
        processId: this.form.processId,
        postId: this.form.postId,
        machineId: this.form.machineId,
        userName: this.form.userName
      }
      machinelist(obj).then(res => {
        console.log(res.data.data, 'res.data.data')
        res.data.data.items.forEach(v => {
          v.machineCode = "";
          v.machines.forEach(value => {
            v.machineCode += value.machineCode + ",";
          });
          console.log(v.machineCode.length, 'v.machineCode.length')
          v.machineCode.length > 0 ? v.machineCode = v.machineCode.substr(0, v.machineCode.length - 1) : "无";
        })
        this.tableData = res.data.data.items;
        this.page.total = res.data.data.total;
        res.data.data.total === 0 ? this.page.total = "0" : "";
      });
      this.$forceUpdate();
    },
    envText: function () {
      return this.env
    }
  }
}
</script>
<style lang="scss" scoped>
.machineClass {
  /deep/ .avue-crud__left {
    width: 100%;
  }

  /deep/ .avue-crud__right {
    width: 100%;
  }
}
</style>
