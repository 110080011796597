var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "basic-container",
        [
          _vm.addVisible
            ? _c(
                "el-dialog",
                {
                  attrs: {
                    title: "添加机台",
                    visible: _vm.addVisible,
                    width: "30%",
                    "append-to-body": true
                  },
                  on: {
                    "update:visible": function($event) {
                      _vm.addVisible = $event
                    }
                  }
                },
                [
                  _c(
                    "el-form",
                    {
                      ref: "ruleForm",
                      staticClass: "demo-ruleForm",
                      attrs: {
                        model: _vm.ruleForm,
                        rules: _vm.rules,
                        "label-width": "100px"
                      }
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "开始锭数", prop: "start" } },
                        [
                          _c("el-input", {
                            model: {
                              value: _vm.ruleForm.start,
                              callback: function($$v) {
                                _vm.$set(_vm.ruleForm, "start", $$v)
                              },
                              expression: "ruleForm.start"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "结束锭数", prop: "end" } },
                        [
                          _c("el-input", {
                            model: {
                              value: _vm.ruleForm.end,
                              callback: function($$v) {
                                _vm.$set(_vm.ruleForm, "end", $$v)
                              },
                              expression: "ruleForm.end"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "p",
                    {
                      staticClass: "dialog-footer",
                      staticStyle: { "margin-top": "-30px" },
                      attrs: { slot: "footer" },
                      slot: "footer"
                    },
                    [
                      _c(
                        "el-button",
                        {
                          on: {
                            click: function($event) {
                              _vm.addVisible = false
                              _vm.ruleForm.start = ""
                              _vm.ruleForm.end = ""
                            }
                          }
                        },
                        [_vm._v("取 消")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.addConfirm }
                        },
                        [_vm._v("确 定")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.dialogVisible
            ? _c(
                "el-dialog",
                {
                  attrs: {
                    title: "人员机台设定",
                    visible: _vm.dialogVisible,
                    width: "60%",
                    "append-to-body": true
                  },
                  on: {
                    "update:visible": function($event) {
                      _vm.dialogVisible = $event
                    }
                  }
                },
                [
                  _c(
                    "div",
                    { staticClass: "machineClass" },
                    [
                      _c(
                        "el-collapse",
                        {
                          model: {
                            value: _vm.activeNames,
                            callback: function($$v) {
                              _vm.activeNames = $$v
                            },
                            expression: "activeNames"
                          }
                        },
                        [
                          _c(
                            "el-collapse-item",
                            { attrs: { title: "人员信息", name: "1" } },
                            [
                              _c(
                                "el-form",
                                {
                                  ref: "ruleform",
                                  staticClass: "content",
                                  attrs: {
                                    align: "center",
                                    "label-width": "70px"
                                  }
                                },
                                [
                                  _c(
                                    "el-row",
                                    { attrs: { gutter: 24 } },
                                    [
                                      _c(
                                        "el-col",
                                        { attrs: { span: 8 } },
                                        [
                                          _c(
                                            "el-form-item",
                                            { attrs: { label: "人员:" } },
                                            [
                                              _c("el-input", {
                                                attrs: { disabled: true },
                                                model: {
                                                  value: _vm.person,
                                                  callback: function($$v) {
                                                    _vm.person = $$v
                                                  },
                                                  expression: "person"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-col",
                                        { attrs: { span: 8 } },
                                        [
                                          _c(
                                            "el-form-item",
                                            { attrs: { label: "班组:" } },
                                            [
                                              _c("el-input", {
                                                attrs: { disabled: true },
                                                model: {
                                                  value: _vm.classAssign,
                                                  callback: function($$v) {
                                                    _vm.classAssign = $$v
                                                  },
                                                  expression: "classAssign"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-col",
                                        { attrs: { span: 8 } },
                                        [
                                          _c(
                                            "el-form-item",
                                            { attrs: { label: "车间:" } },
                                            [
                                              _c("el-input", {
                                                attrs: { disabled: true },
                                                model: {
                                                  value: _vm.wkshp,
                                                  callback: function($$v) {
                                                    _vm.wkshp = $$v
                                                  },
                                                  expression: "wkshp"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-row",
                                    { attrs: { gutter: 24 } },
                                    [
                                      _c(
                                        "el-col",
                                        { attrs: { span: 8 } },
                                        [
                                          _c(
                                            "el-form-item",
                                            { attrs: { label: "岗位:" } },
                                            [
                                              _c("el-input", {
                                                attrs: { disabled: true },
                                                model: {
                                                  value: _vm.postName,
                                                  callback: function($$v) {
                                                    _vm.postName = $$v
                                                  },
                                                  expression: "postName"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-col",
                                        { attrs: { span: 8 } },
                                        [
                                          _c(
                                            "el-form-item",
                                            { attrs: { label: "工序:" } },
                                            [
                                              _c("el-input", {
                                                attrs: { disabled: true },
                                                model: {
                                                  value: _vm.processName,
                                                  callback: function($$v) {
                                                    _vm.processName = $$v
                                                  },
                                                  expression: "processName"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-collapse-item",
                            { attrs: { title: "机台设定", name: "2" } },
                            [
                              _c(
                                "avue-crud",
                                {
                                  staticStyle: {
                                    display: "block",
                                    overflow: "scroll !important",
                                    height: "350px"
                                  },
                                  attrs: {
                                    option: _vm.classOption,
                                    data: _vm.classData
                                  },
                                  on: { "row-del": _vm.handleDel }
                                },
                                [
                                  _c("template", { slot: "menuLeft" }, [
                                    _c(
                                      "div",
                                      [
                                        _c("el-autocomplete", {
                                          attrs: {
                                            "popper-class": "my-autocomplete",
                                            "fetch-suggestions":
                                              _vm.querySearch,
                                            placeholder: "请输入内容"
                                          },
                                          on: { select: _vm.handleSelect },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function(ref) {
                                                  var item = ref.item
                                                  return [
                                                    _c(
                                                      "div",
                                                      { staticClass: "name" },
                                                      [
                                                        _vm._v(
                                                          _vm._s(item.code)
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                }
                                              }
                                            ],
                                            null,
                                            false,
                                            1011598655
                                          ),
                                          model: {
                                            value: _vm.nameorcode,
                                            callback: function($$v) {
                                              _vm.nameorcode = $$v
                                            },
                                            expression: "nameorcode"
                                          }
                                        }),
                                        _c(
                                          "el-button",
                                          {
                                            staticStyle: { marginLeft: "5px" },
                                            attrs: {
                                              size: "small",
                                              type: "primary"
                                            },
                                            on: { click: _vm.addDriver }
                                          },
                                          [_vm._v("添加")]
                                        )
                                      ],
                                      1
                                    )
                                  ])
                                ],
                                2
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "p",
                    {
                      staticClass: "dialog-footer",
                      staticStyle: { "margin-top": "-30px" },
                      attrs: { slot: "footer" },
                      slot: "footer"
                    },
                    [
                      _c(
                        "el-button",
                        {
                          on: {
                            click: function($event) {
                              _vm.dialogVisible = false
                            }
                          }
                        },
                        [_vm._v("取 消")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: {
                            type: "primary",
                            disabled: _vm.classData.length == 0 ? true : false
                          },
                          on: { click: _vm.confirm }
                        },
                        [_vm._v("确 定")]
                      )
                    ],
                    1
                  )
                ]
              )
            : _vm._e(),
          _c(
            "avue-crud",
            {
              ref: "crud",
              attrs: {
                option: _vm.tableOption,
                data: _vm.tableData,
                "table-loading": _vm.tableLoading,
                page: _vm.page
              },
              on: {
                "refresh-change": _vm.refreshChange,
                "row-save": _vm.handleSave,
                "current-change": _vm.currentChange,
                "size-change": _vm.sizeChange,
                "row-update": _vm.handleUpdate,
                "row-del": _vm.handleDel
              },
              scopedSlots: _vm._u([
                {
                  key: "machineCode",
                  fn: function(ref) {
                    var row = ref.row
                    return [
                      _c(
                        "el-link",
                        {
                          staticStyle: {
                            color: "#409EFF",
                            "text-style": "none"
                          },
                          on: {
                            click: function($event) {
                              return _vm.clickLink(row)
                            }
                          }
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(row.machineCode || "无") +
                              "\n        "
                          )
                        ]
                      )
                    ]
                  }
                }
              ])
            },
            [
              _c(
                "template",
                { slot: "menuRight" },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: {
                        width: "100px !important",
                        marginRight: "5px"
                      },
                      attrs: {
                        filterable: "",
                        placeholder: "车间",
                        size: "small"
                      },
                      on: { change: _vm.cjChange },
                      model: {
                        value: _vm.form.workShopId,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "workShopId", $$v)
                        },
                        expression: "form.workShopId"
                      }
                    },
                    _vm._l(_vm.calssLists, function(item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: { label: item.name, value: item.id }
                      })
                    }),
                    1
                  ),
                  _c(
                    "el-select",
                    {
                      staticStyle: {
                        width: "100px !important",
                        marginRight: "5px"
                      },
                      attrs: {
                        filterable: "",
                        placeholder: "班组",
                        size: "small"
                      },
                      model: {
                        value: _vm.form.groupId,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "groupId", $$v)
                        },
                        expression: "form.groupId"
                      }
                    },
                    _vm._l(_vm.refrechLists, function(item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: { label: item.name, value: item.id }
                      })
                    }),
                    1
                  ),
                  _c(
                    "el-select",
                    {
                      staticStyle: {
                        width: "100px !important",
                        marginRight: "5px"
                      },
                      attrs: {
                        filterable: "",
                        clearable: "",
                        placeholder: "工序",
                        size: "small"
                      },
                      on: { change: _vm.gxChange },
                      model: {
                        value: _vm.form.processId,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "processId", $$v)
                        },
                        expression: "form.processId"
                      }
                    },
                    _vm._l(_vm.wklnLists, function(item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.name, value: item.id }
                      })
                    }),
                    1
                  ),
                  _c(
                    "el-select",
                    {
                      staticStyle: {
                        width: "100px !important",
                        marginRight: "5px"
                      },
                      attrs: {
                        filterable: "",
                        clearable: "",
                        placeholder: "岗位",
                        size: "small"
                      },
                      model: {
                        value: _vm.form.postId,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "postId", $$v)
                        },
                        expression: "form.postId"
                      }
                    },
                    _vm._l(_vm.workLists, function(item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.name, value: item.id }
                      })
                    }),
                    1
                  ),
                  _c(
                    "el-select",
                    {
                      staticStyle: {
                        width: "100px !important",
                        marginRight: "5px"
                      },
                      attrs: {
                        filterable: "",
                        clearable: "",
                        placeholder: "设备",
                        size: "small"
                      },
                      model: {
                        value: _vm.form.machineId,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "machineId", $$v)
                        },
                        expression: "form.machineId"
                      }
                    },
                    _vm._l(_vm.mechineList, function(item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.name, value: item.id }
                      })
                    }),
                    1
                  ),
                  _c("el-input", {
                    staticStyle: {
                      display: "inlie-block",
                      width: "200px",
                      marginRight: "5px"
                    },
                    attrs: {
                      clearable: "",
                      placeholder: "员工姓名",
                      size: "small"
                    },
                    model: {
                      value: _vm.form.userName,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "userName", $$v)
                      },
                      expression: "form.userName"
                    }
                  })
                ],
                1
              )
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }